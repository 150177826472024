import produce from 'immer';
import { byId, isVal, remove, uuid } from 'utils/utils';

const invoiceReducer = (state, [action, data]) => {
  return produce(state, (draft) => {
    if (action !== a.INIT) draft.isDirty = true;
    switch (action) {
      case a.INIT:
        return {
          DATA_LOADED: true,
          isFailed: data?.state === 'FAILED',
          editable: ['COLLECTING', 'ACKNOWLEDGED'].includes(data?.assignmentState),
          displayState: getDisplayState(data).state,
          ...data,
        };

      case a.SKU_QTY:
        {
          byId(draft.lineItems, data.id).deliveredQuantity = data.qty;
        }
        break;

      case a.STATE:
        draft.displayState = data;
        if (data === 'FAILED') {
          draft.isFailed = true;
        } else {
          draft.stateMessage = null;
          draft.isFailed = false;
        }
        break;

      case a.STATE_REASON:
        draft.stateMessage = data;
        break;

      case a.SAVED:
        draft.isDirty = false;
        break;

      case a.ADD_CASH:
        draft.payments.push({
          id: uuid(),
          modeOfPayment: 'CASH',
          amountCollected: 0,
          editable: true,
        });
        break;

      case a.ADD_CHEQUE:
        draft.payments.push({
          id: uuid(),
          modeOfPayment: 'CHEQUE',
          amountCollected: 0,
          editable: true,
          modeDetails: {
            bankId: null,
            chequeNo: null,
            chequeDate: null,
          },
        });
        break;

      case a.DELETE_PAYMENT:
        draft.payments = remove(draft.payments, data, 'id');
        break;

      case a.EDIT_AMOUNT:
        byId(draft.payments, data.id).amountCollected = data.value;
        break;

      case a.PAYMENT_DETAIL_EDIT:
        byId(draft.payments, data.id).modeDetails[data.key] = data.value;
        break;

      case a.POD_EXTRA:
        if (!state.pod) draft.pod = {};
        if (!state.pod.extraFields) draft.pod.extraFields = {};
        draft.pod.extraFields[data.key] = data.value;
        break;

      case a.POD_IMG:
        if (!state.pod) draft.pod = {};
        if (!state.pod.images) draft.pod.images = [];
        draft.pod.images.push(...data);
        draft.image_upload = false;
        draft.pod_images = undefined;
        break;
      case a.TEMP_POD_IMG:
        draft.image_upload = true;
        if (!state.pod_images) draft.pod_images = [];
        draft.pod_images.push(data);
        break;

      case a.DAMAGE_RETURN:
        draft.damageReturn = data;
        break;

      case a.SRN_VALUE:
        draft.srnValue = data;
        break;

      default:
        return state;
    }
  });
};

export function getPayload(state) {
  const payload = [
    {
      invoiceId: state.id,
      state: getDeliveryState(state),
      payments: state.payments.map((pmt) => ({
        amount: Number(pmt.amountCollected),
        mode: pmt.modeOfPayment,
        details: pmt.modeDetails,
      })),
      lineItemUpdates: state.lineItems.map((lineItem) => ({
        lineItemId: lineItem.id,
        deliveredQuantity: isVal(lineItem.deliveredQuantity)
          ? lineItem.deliveredQuantity
          : lineItem.quantity,
      })),
      damageReturn: Number(state.damageReturn),
      srnValue: Number(state.srnValue),
      images: state.images,
      pod: state.pod,
    },
  ];

  if (state.isFailed) {
    payload[0].stateReason = state.stateMessage;
    payload[0].lineItemUpdates = [];
  }

  return payload;
}

function getDeliveryState(state) {
  if (state.isFailed) return 'FAILED';
  if (!state.displayState) return state.displayState;

  return state.lineItems.some(({ quantity, deliveredQuantity }) => quantity - deliveredQuantity)
    ? 'PARTIAL_DELIVERED'
    : 'DELIVERED';
}

export function getDisplayState({ state, supplier }) {
  if (state === 'FAILED' || !state) return { state, isPartial: false };
  return { state: supplier ? 'PICKED' : 'DELIVERED', isPartial: state === 'PARTIAL_DELIVERED' };
}

export const a = {
  INIT: 'INIT',
  ADD_CASH: 'ADD_CASH',
  ADD_CHEQUE: 'ADD_CHEQUE',
  DELETE_PAYMENT: 'DELETE_PAYMENT',
  EDIT_AMOUNT: 'EDIT_AMOUNT',
  PAYMENT_DETAIL_EDIT: 'PAYMENT_DETAIL_EDIT',
  STATE_REASON: 'STATE_REASON',
  SKU_QTY: 'SKU_QTY',
  STATE: 'STATE',
  SAVED: 'SAVED',
  POD_EXTRA: 'POD_EXTRA',
  POD_IMG: 'POD_IMG',
  TEMP_POD_IMG: 'TEMP_POD_IMG',
  DAMAGE_RETURN: 'DAMAGE_RETURN',
  SRN_VALUE: 'SRN_VALUE',
};

export default invoiceReducer;
