import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/CloseRounded';
import {
  Slide,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Dialog,
  Box,
  TextField,
  Divider,
  Button,
} from '@material-ui/core';
import CButton from 'components/CButton';
import { useFetch } from 'utils/customHooks';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import { a } from '../invoiceReducer';
import { byId, formatDate, formatCurrency0 } from 'utils/utils';
import Select from 'react-select';
import { MobileDatePicker } from '@material-ui/pickers';
import { CaptionWithValue } from 'components/TypographyUtils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default function Payments({
  paymentsDialogClose,
  paymentsDialogOpen,
  saveInvoice,
  loading,
  state,
  dispatch,
}) {
  const classes = useStyles();
  const [bankMaster] = useFetch(`/collection/bankMaster?branchId=${state.branchId}`);
  const readOnly = !state.editable;
  const outstanding = state.value - (state.srnValue || 0) - (state.damageReturn || 0) || 0;
  const collected = state?.payments.reduce((acc, pmt) => acc + (pmt.amountCollected || 0), 0);
  const modeWisePayments = state?.payments
    .filter(function (pmt) {
      return (
        pmt.modeDetails?.upiTrxStatus === undefined || pmt.modeDetails?.upiTrxStatus === 'success'
      );
    })
    .reduce((res, ele) => {
      res[ele.modeOfPayment] = [...(res[ele.modeOfPayment] || []), ele];
      return res;
    }, {});
  const groupedPayments = Object.keys(modeWisePayments).flatMap((modeOfPayment) => {
    const pmts = modeWisePayments[modeOfPayment];
    return modeOfPayment === 'UPI'
      ? {
          id: pmts[0].id,
          modeOfPayment: modeOfPayment,
          amountCollected: pmts.reduce((sum, ele) => {
            return sum + (ele.amountCollected || 0);
          }, 0),
        }
      : pmts;
  });

  return (
    <Dialog
      fullScreen
      open={paymentsDialogOpen}
      onClose={paymentsDialogClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton edge="start" color="inherit" onClick={paymentsDialogClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Payments
          </Typography>
          <CButton
            disabled={!state.isDirty}
            loading={loading}
            onClick={() => {
              saveInvoice();
              // podDialogClose();
            }}
            color="secondary"
            outlined={true}
          >
            <Typography>SAVE</Typography>
          </CButton>
        </Toolbar>
      </AppBar>
      <Box display="flex" alignItems="center" justifyContent="space-around" m={2} mb={0}>
        <CaptionWithValue
          value={formatCurrency0(outstanding)}
          label="Outstanding"
          options={{ boundary: false }}
        />
        <Typography>=</Typography>
        <CaptionWithValue
          value={formatCurrency0(outstanding - collected)}
          label="Credit"
          options={{ boundary: false }}
        />
        <Typography>+</Typography>
        <CaptionWithValue
          value={formatCurrency0(collected)}
          label="Collected"
          options={{ boundary: false }}
        />
      </Box>
      {groupedPayments
        // .sort((a, b) => a.modeOfPayment - b.modeOfPayment)
        .map((pmt) => {
          switch (pmt.modeOfPayment) {
            case 'CASH':
              return <Cash key={pmt.id} pmt={pmt} dispatch={dispatch} readOnly={readOnly}></Cash>;
            case 'UPI':
              return <UPI key={pmt.id} pmt={pmt} dispatch={dispatch}></UPI>;
            default:
              return (
                <Cheque
                  key={pmt.id}
                  {...{ pmt, banks: bankMaster?.banks, dispatch }}
                  readOnly={readOnly}
                ></Cheque>
              );
          }
        })}
      <Box display="flex" alignItems="center" justifyContent="space-around" m={2} p={1}>
        <Button
          color="primary"
          disabled={state.payments.some((pmt) => pmt.modeOfPayment === 'CASH') || readOnly}
          onClick={() => dispatch([a.ADD_CASH])}
        >
          Add Cash
        </Button>
        <Button color="primary" onClick={() => dispatch([a.ADD_CHEQUE])} disabled={readOnly}>
          Add Cheque
        </Button>
      </Box>
    </Dialog>
  );
}

function Cash({ pmt: { id, modeOfPayment, amountCollected }, dispatch, readOnly }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderRadius={16}
      m={2}
      p={2}
      border={1}
      borderColor="grey.700"
    >
      <Box px={1}>
        <Typography>{modeOfPayment}</Typography>
      </Box>

      <TextField
        placeholder="Amount"
        variant="outlined"
        value={amountCollected || ''}
        size="small"
        type="number"
        disabled={readOnly}
        onChange={(e) => dispatch([a.EDIT_AMOUNT, { id, value: Number(e.target.value) }])}
      ></TextField>
      <IconButton onClick={() => dispatch([a.DELETE_PAYMENT, id])} disabled={readOnly}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
}

function Cheque({
  pmt: {
    id,
    modeOfPayment,
    amountCollected,
    modeDetails: { chequeNo, chequeDate, bankId },
  },
  banks,
  dispatch,
  readOnly,
}) {
  const bankOptions = useMemo(() => banks?.map((b) => ({ value: b.id, label: b.bankBranchName })), [
    banks,
  ]);
  return (
    <Box borderRadius={16} border={1} borderColor="grey.700" m={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
        <Box px={1}>
          <Typography>{modeOfPayment}</Typography>
        </Box>
        <TextField
          placeholder="Amount"
          variant="outlined"
          value={amountCollected || ''}
          size="small"
          type="number"
          onChange={(e) => dispatch([a.EDIT_AMOUNT, { id, value: Number(e.target.value) }])}
          required={true}
          disabled={readOnly}
        ></TextField>
        <IconButton onClick={() => dispatch([a.DELETE_PAYMENT, id])} disabled={readOnly}>
          <DeleteIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box minWidth={1 / 2} p={1}>
          <TextField
            variant="outlined"
            size="small"
            label="Cheque Number"
            value={chequeNo}
            disabled={readOnly}
            onChange={(e) =>
              dispatch([a.PAYMENT_DETAIL_EDIT, { id, key: 'chequeNo', value: e.target.value }])
            }
            required={true}
          />
        </Box>
        <Box minWidth={1 / 2} p={1}>
          <MobileDatePicker
            autoOk
            variant="outlined"
            label="Cheque Date"
            size="small"
            placeholder="Cheque Date"
            format="dd-MM-yyyy"
            mask="__-__-____"
            value={chequeDate}
            onChange={(date) => {
              dispatch([a.PAYMENT_DETAIL_EDIT, { id, key: 'chequeDate', value: formatDate(date) }]);
            }}
            disabled={readOnly}
            required={true}
          />
        </Box>
      </Box>
      <Box
        px={2}
        pb={2}
        width={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box minWidth={1} px={1} pb={1}>
          <Select
            placeholder="Bank Branch Name"
            value={byId(bankOptions, bankId, 'value')}
            options={bankOptions}
            onChange={({ value }) =>
              dispatch([a.PAYMENT_DETAIL_EDIT, { id, key: 'bankId', value }])
            }
            disabled={readOnly}
            required={true}
          />
        </Box>
      </Box>
    </Box>
  );
}

function UPI({ pmt: { modeOfPayment, amountCollected } }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderRadius={16}
      m={2}
      p={2}
      border={1}
      borderColor="grey.700"
    >
      <Box px={1}>
        <Typography>{modeOfPayment}</Typography>
      </Box>

      <TextField
        variant="outlined"
        value={amountCollected}
        size="small"
        type="number"
        disabled
      ></TextField>
    </Box>
  );
}
