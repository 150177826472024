import {
  AppBar,
  Checkbox,
  IconButton,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core';
import NoSsr from '@material-ui/core/NoSsr';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import ClearIcon from '@material-ui/icons/Clear';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Dialog, DialogContent, DialogTitle } from 'components/feedbacks/Dialog';
import Button from 'components/inputs/ButtonM';
import CustomizedMenus from 'components/inputs/Menu';
import { navigate } from 'components/Link';
import Maps from 'components/Maps';
import Text from 'components/Text';
import qs from 'query-string';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import { useGState } from 'state/store';
import { useAPI, useKeyPress } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { mapStyles } from 'utils/mapsUtils';
import { formatCurrency } from 'utils/utils';
import ImageUploader from './ImageUploader';
import { BackButton, deliveryStateOptions, getFailureOptions, GridContainer } from './Invoice';
import { getDisplayState } from './invoiceReducer';
import { CardContainer, Header, PaymentModeContainer, PaymentModeContent } from './Payments';

export default function RetailerAssignment({ location }) {
  const [fetchApi] = useAPI();

  const { assignmentId, retailerId } = qs.parse(location.search);
  const [selectedInvoices, setSelectedInvoices] = useState({});
  const deliveryStates = {
    DELIVERED: { label: 'Success', color: 'bg-success-green' },
    PARTIAL_DELIVERED: { label: 'Partial', color: 'bg-yellow-300' },
    FAILED: { label: 'Failed', color: 'bg-red-400' },
    NOT_VISITED: { label: 'Pending', color: 'bg-inactive-dark' },
  };
  const { data, refetch } = useQuery(
    ['retailer_invoice', { assignmentId, retailerId }],
    (_, { assignmentId, retailerId }) =>
      fetch(
        `/v2/delivery/dlo/retailer/invoice?assignmentId=${assignmentId}&retailerId=${retailerId}`
      )
  );

  const { data: retailerData, refetch: d_refetch } = useQuery(
    ['retailer_invoice_data', { assignmentId, retailerId }],
    (_, { assignmentId, retailerId }) =>
      fetch(
        `/v3/delivery/panel/retailer/invoice?assignmentId=${assignmentId}&retailerId=${retailerId}`
      )
  );

  const [callRetailer] = useMutation(() =>
    fetch({
      method: 'POST',
      url: `/v3/delivery/panel/call?assignmentId=${assignmentId}&retailerId=${retailerId}`,
    })
  );

  const { dloAppBeta } = useGState((s) => ({ dloAppBeta: s.clientPreference.dloAppBeta }));

  const handleReturn = (invoiceId, returnStatus) => {
    fetchApi(
      {
        method: 'PUT',
        url: `/v2/delivery/dlo/invoice/return?assignmentId=${assignmentId}`,
        data: { invoiceId, returnStatus },
      },
      () => {
        refetch();
        d_refetch();
      }
    );
  };
  const handlePickupClick = (payload) => {
    const invoiceIds = retailerData.invoices
      .filter(
        (invoice) => invoice.type === 'PICKUP' && selectedInvoices[invoice.id]?.selected == true
      )
      .map((invoice) => invoice.id);

    fetchApi(
      {
        method: 'PUT',
        url: `/v3/delivery/panel/multi-invoice/state?assignmentId=${assignmentId}`,
        data: {
          invoiceIds,
          ...payload,
        },
      },
      () => {
        refetch();
        d_refetch();
      }
    );
  };

  const gotToInvoice = (invoiceId) => {
    dloAppBeta
      ? navigate(
          `/dl/retailerAssignment/invoice/?assignmentId=${assignmentId}&retailerId=${retailerId}&invoiceId=${invoiceId}`
        )
      : navigate(
          `/dl/retailerAssignment/oldInvoice/?assignmentId=${assignmentId}&retailerId=${retailerId}&invoiceId=${invoiceId}`
        );
  };

  if (!data || !retailerData) return <LinearProgress color="secondary" />;
  const deliverState = deliveryStates[data.state];
  return (
    <>
      <AppBar position="sticky">
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => navigate(`/dl/retailers/${assignmentId}`)}
            edge="start"
          >
            <ArrowBackIcon />
          </IconButton>

          <Typography variant="h6">{data.code}</Typography>
          {data && <Typography variant="subtitle1">{`: ${data?.name}`}</Typography>}
        </Toolbar>
      </AppBar>
      <div class="flex flex-col px-5 sm:px-10 md:px w-1/1 mb-6 mt-8">
        <div class="flex-none z-10">
          <BackButton onClick={() => navigate(`/dl/retailers/${assignmentId}`)} />
        </div>
        <div class="flex-grow mt-5 md:-mt-10">
          <GridContainer>
            <CardContainer twCls="col-span-all">
              <div class="flex flex-col md:flex-row justify-between px-10 py-5">
                <div class="md:mr-4">
                  <div class="flex flex-col md:flex-row justify-between">
                    <img
                      class="rounded-lg max-w-xs mr-10 h-36"
                      src={retailerData.retailer.images[0] || require('images/photo.png')}
                    />
                    <div class="flex flex-col items-start mt-3 md:mt-0">
                      <p class="font-medium text-inactive uppercase text-sm">{data.code}</p>
                      <p class="font-bold text-dark_blue text-xl">{data.name}</p>
                      <p class="font-medium text-inactive capitalize text-sm">
                        {retailerData.retailer.address.join(' ')}
                      </p>
                      <div
                        className={`rounded-lg ${deliverState?.color} content-center mt-3 px-3 py-1`}
                      >
                        <p class="font-medium text-white text-base">{deliverState?.label}</p>
                      </div>
                    </div>
                  </div>
                  {(retailerData.retailer.name || retailerData.retailer.phoneMasked) && (
                    <div class="mt-10 items-start">
                      <p class="text-xs tracking-wide font-medium text-inactive uppercase mb-2">
                        Owner Details
                      </p>
                      <div class="flex flex-col md:flex-row items-start justify-around rounded-lg px-4 py-3 border border-gray-200 text-base font-medium text-inactive">
                        {retailerData.retailer.name && (
                          <div class="flex flex-row mr-5 mb-2 md:mb-0">
                            <PersonIcon color="inherit" />
                            <p class="font-medium text-tertiary ml-1">
                              {retailerData.retailer.name}
                            </p>
                          </div>
                        )}
                        {retailerData.retailer.phoneMasked && (
                          <div
                            class="flex flex-row items-start cursor-pointer"
                            onClick={() => callRetailer()}
                          >
                            <PhoneIcon color="inherit" />
                            <p class="font-medium text-tertiary ml-1">
                              {retailerData.retailer.phoneMasked}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <NoSsr>
                  <Suspense fallback={<LinearProgress color="secondary" />}>
                    <div
                      class="rounded-lg ml-0 md:ml-5 mt-5 w-full md:w-1/2 md:mt-0"
                      style={{ height: '17rem' }}
                    >
                      <Maps render={() => <MapCard />} height="100%" />
                    </div>
                  </Suspense>
                </NoSsr>
              </div>
            </CardContainer>
            <InvoicesDetails
              data={retailerData.invoices}
              supplier={data.supplier}
              gotToInvoice={gotToInvoice}
              editable={['COLLECTING', 'ACKNOWLEDGED'].includes(data?.assignmentState)}
              handleReturn={handleReturn}
              selectedInvoices={selectedInvoices}
              setSelectedInvoices={setSelectedInvoices}
              handlePickupClick={handlePickupClick}
            />

            <FullCaseCard data={retailerData.handlingUnits.filter((h) => h.type == 'FULL CASE')} />
            <BoxDetailsCard data={retailerData.handlingUnits.filter((h) => h.type == 'CRATE')} />
          </GridContainer>
        </div>
      </div>
    </>
  );
}

function BoxDetailsCard(props) {
  const { data = [] } = props;
  if (data.length == 0) return <></>;

  return (
    <CardContainer>
      <Header title="Box Details" />
      <div
        class="my-5 mx-10"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(10rem, 1fr))',
          gap: '1rem',
        }}
      >
        {data.map((fullcase, index) => (
          <div
            key={index}
            class="flex flex-row py-3 break-avoid px-6 items-center justify-between border rounded-lg shadow"
          >
            <img class="rounded-md w-8 h-6 mr-3" src={require('images/crate.png')} />
            <div>
              <p class="font-medium text-base text-tertiary">{fullcase.code}</p>
              <p class="text-sm font-medium text-inactive">{`${fullcase.skuDetails.length} Items`}</p>
            </div>
          </div>
        ))}
      </div>
    </CardContainer>
  );
}
function InvoiceContent(props) {
  const { title, amount } = props;
  return (
    <div>
      <span class="text-xs">{title}</span>
      <p class="text-base font-medium">{amount ? formatCurrency(amount) : '--'}</p>
    </div>
  );
}

function FullCaseCard(props) {
  const { data = [] } = props;
  if (data.length == 0) return <></>;

  return (
    <CardContainer>
      <Header title="Full Cases" />
      {data.map((fullcase, index) => (
        <div
          key={fullcase.name + index}
          class="my-5 mx-10 py-5 px-6 flex flex-row items-center justify-between border border-1 border-inactive rounded-lg"
        >
          <div class="flex flex-row justify-start items-center">
            <img class="rounded-md" src={fullcase.skuDetails[0].skuImages[0]} />
            <p class="text-base font-medium text-tertiary ml-4">{fullcase.skuDetails[0].skuName}</p>
          </div>
          <p class="font-medium text-inactive">{fullcase.code}</p>
        </div>
      ))}
    </CardContainer>
  );
}

function FailedPickupModal(props) {
  const { open, close, failPickup } = props;

  const {
    clientPreference: { failReasons = [] },
  } = useGState((s) => ({ clientPreference: s.clientPreference }));

  const [reason, setReason] = useState();

  const [images, setImages] = useState();

  const options = failReasons.map((r) => ({ value: r, label: r }));

  const onSubmit = () => {
    failPickup({ messages: [reason.label], images });
    setImages(undefined);
    // setReason(undefined);
  };

  useEffect(() => {
    return () => {
      setReason(undefined);
    };
  }, []);

  return (
    <Dialog
      scroll="paper"
      open={open}
      onClose={close}
      classes={{ paper: 'py-1 px-2 rounded-lg md:w-1/2 lg:w-1/4 min-h-60' }}
    >
      <DialogTitle>
        <p class="text-xl font-bold mb-4">Reason for Failed Pickup</p>
      </DialogTitle>
      <DialogContent class="p-6 pt-1 justify-center" dividers>
        <div className="mb-4">
          <Select
            class="flex-1"
            options={options}
            value={reason}
            onChange={setReason}
            isSearchable={true}
          />
          <Text class="text-sm font-bold mt-5">Upload Photos</Text>
          <ImageUploader id="reason" images={images} onChange={setImages} />
        </div>
        <Button class="text-white bg-dark_blue px-5 py-2 rounded-lg mt-5" onClick={onSubmit}>
          Submit
        </Button>
      </DialogContent>
    </Dialog>
  );
}
function InvoicesDetails(props) {
  const { selectedInvoices, setSelectedInvoices, handlePickupClick, editable } = props;
  const [filterText, setFilterText] = useState();
  const [isAll, setIsAll] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    clientPreference: { enableRetrys },
  } = useGState((s) => ({ clientPreference: s.clientPreference }));

  const deliveryFilter = (invoice) => invoice.type != 'PICKUP';
  const pickUpFilter = (invoice) => invoice.type == 'PICKUP';

  const delivery = props.data.filter(deliveryFilter);
  const pickup = props.data.filter(pickUpFilter);
  const [selectedTabIndex, setSelectedTabIndex] = useState(delivery.length ? 0 : 1);

  const multiSelectable = props.data.some((i) => i.multiSelectable) && selectedTabIndex == 1;
  const isSelected = Object.values(selectedInvoices).some((v) => v.selected);
  const isSelectedAllFailed = !Object.values(selectedInvoices).some((v) => v.selected && !v.failed);

  const isMatching = (code) => {
    if (!filterText) return true;
    return code.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1;
  };

  const invoiceFilter = selectedTabIndex == 0 ? deliveryFilter : pickUpFilter;

  const filteredData = props.data.filter(invoiceFilter);

  const selectAll = (val = true) => {
    setIsAll(val);
    const invoices = filteredData.reduce((acc, i) => {
      acc[i.id] = { selected: val, failed: i.state === 'FAILED' };
      return acc;
    }, {});
    setSelectedInvoices(invoices);
  };

  const unselectAll = () => selectAll(false);

  const close = () => {
    setOpen(false);
  };

  const data = [
    {
      label: `Delivery (${delivery.length})`,
      disabled: delivery.length == 0,
      hide: true,
      component: <DeliveryCard {...props} data={delivery.filter((i) => isMatching(i.code))} />,
    },
    {
      label: `Pickup (${pickup.length})`,
      disabled: pickup.length == 0,
      hide: true,
      component: <PickupCard {...props} data={pickup.filter((i) => isMatching(i.code))} />,
    },
  ];
  const handleSrnRetry = (state) => {
    handlePickupClick({ state });
    setSelectedInvoices({});
  };

  const failPickup = (data) => {
    handlePickupClick({ ...data, state: 'FAILED' });
    setOpen(false);
    setSelectedInvoices({});
  };

  const fail = () => {
    setOpen(true);
  };

  const picked = () => {
    handlePickupClick({ state: 'DELIVERED' });
    setSelectedInvoices({});
  };

  const handleSelectUnselect = () => {
    isAll ? unselectAll() : selectAll();
  };
  useEffect(() => {
    const isAll = !filteredData.some((d) => !selectedInvoices[d.id]?.selected);

    setIsAll(isAll);
  }, [filteredData, selectedInvoices]);

  if (pickup.length == 0 && delivery.length == 0) {
    return <></>;
  }

  return (
    <ContainerWithTabs
      data={data}
      selectedTabIndex={selectedTabIndex}
      onChange={setSelectedTabIndex}
    >
      <div class="flex flex-row mt-4 mb-1 items-start justify-between">
        <div class="pl-6 flex-col md:flex-row">
          {multiSelectable && (
            <button
              class="text-theme shadow bg-white px-5 py-2 rounded-lg mr-3 mb-3 capitalize"
              onClick={handleSelectUnselect}
            >
              {(isAll ? 'Un' : '') + 'select All'}
            </button>
          )}
          <div class="relative inline-flex">
            <input
              type="text"
              value={filterText}
              placeholder="search invoice"
              class="border border-solid px-5 py-2 rounded-lg"
              onChange={(e) => setFilterText(e.target.value)}
            ></input>
            <button
              class="hover:text-inactive rounded-lg absolute right-0 top-1 z-10 px-2 py-1"
              onClick={() => setFilterText('')}
            >
              <ClearIcon fontSize="inherit" />
            </button>
          </div>
        </div>

        {multiSelectable && isSelectedAllFailed && (
          <div className="pr-6">
            <CustomizedMenus
              disabled={!editable}
              buttonCls="py-2 px-0 rounded-lg"
              data={getFailureOptions(enableRetrys)}
              onValueChange={(item) => handleSrnRetry(item.text)}
            />
          </div>
        )}
        {multiSelectable && isSelected && (
          <div class="pr-6 text-right">
            <Button class="text-white bg-success-dark px-5 py-2 rounded-lg mb-3" onClick={picked}>
              Picked Up
            </Button>
            <Button class="text-white bg-warn-dark px-5 py-2 rounded-lg ml-3" onClick={fail}>
              Failed
            </Button>
          </div>
        )}
      </div>
      <FailedPickupModal open={open} close={close} failPickup={failPickup} />
    </ContainerWithTabs>
  );
}

function ContainerWithTabs(props) {
  const { data = [], children, onChange, selectedTabIndex } = props;
  const [value, setValue] = useState(selectedTabIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };
  const components = data.map((d) => d.component);

  return (
    <CardContainer>
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          variant="fullWidth"
          aria-label="scrollable auto tabs"
          centered
        >
          {data.map((d) => (
            <Tab
              key={d.label}
              label={d.label}
              disabled={d.disabled}
              classes={{ root: 'font-semibold capitalize text-base' }}
            />
          ))}
        </Tabs>
      </Paper>
      {children}
      {components[value]}
    </CardContainer>
  );
}

function InvoiceHeader(props) {
  const { setSelectedInvoices, selectedInvoices, invoice, checkbox = false } = props;
  return (
    <div class="flex flex-row mr-3 items-center">
      {checkbox && (
        <Checkbox
          color="default"
          value={invoice.id}
          checked={Boolean(selectedInvoices[invoice.id]?.selected)}
          inputProps={{ 'aria-label': 'checkbox' }}
          onChange={({ target: { checked } }) =>
            setSelectedInvoices((invoices) => ({
              ...invoices,
              [invoice.id]: { selected: checked, failed: invoice.state === 'FAILED' },
            }))
          }
        />
      )}
      <div class="w-6 ml-2">
        <DescriptionOutlinedIcon />
      </div>
      <div>
        <p class="ml-4 font-medium">{invoice.code}</p>
        <p class="ml-4 font-base">{`${invoice.lineItemCount || 0} Products`}</p>
      </div>
    </div>
  );
}

function FailedSRN(props) {
  const { invoice, supplier, editable, handleReturn } = props;
  const displayState = getDisplayState({ state: invoice.state, supplier }).state;

  if (invoice.state == 'FAILED') {
    return (
      <FailureSRNAndRetry
        data={{
          isFailed: invoice.state === 'FAILED',
          displayState: displayState,
          returnState: invoice.returnState,
          supplier,
          editable,
          invoiceId: invoice.id,
        }}
        isPickup={invoice.type === 'PICKUP'}
        handleReturn={handleReturn}
      />
    );
  }
  let color = 'gray';
  if (invoice?.state) {
    if (invoice?.state === 'DELIVERED') color = '#12b981';
    else if (invoice?.state === 'PARTIAL_DELIVERED') color = '#f69e0b';
  }

  if (displayState === 'PICKED') color = '#12b981';
  return (
    <div class={`rounded-lg content-center px-4 py-2`} style={{ backgroundColor: color }}>
      <p class="font-medium text-inactive-dark text-white">{displayState || 'Pending'}</p>
    </div>
  );
}

function FailedMessage(props) {
  const { isFailed, message } = props;
  if (!isFailed) return <></>;

  return (
    <div class="mt-10">
      <p class="text-xs">Reason for Failed invoice</p>
      <div class="flex flex-row mt-3 items-center justify-start">
        {/* <img class="rounded-md" src="https://picsum.photos/30/30/?blur" /> */}
        <p class="font-semibold ml-3">{message}</p>
      </div>
    </div>
  );
}
function DeliveryCard(props) {
  const { data = [], gotToInvoice, selectedInvoices, setSelectedInvoices } = props;
  if (data.length == 0) return <></>;

  return (
    <div>
      {data.map((invoice) => (
        <PaymentModeContent key={invoice.id}>
          <PaymentModeContainer>
            <div class="flex flex-row items-center justify-between bg-background px-6 py-4 rounded-lg">
              <InvoiceHeader
                selectedInvoices={selectedInvoices}
                setSelectedInvoices={setSelectedInvoices}
                invoice={invoice}
                checkbox={false}
              />
              <FailedSRN invoice={invoice} {...props} />
            </div>
            <PaymentModeContent>
              <div class="flex flex-row justify-between">
                <InvoiceContent title="Amount" amount={invoice.value} />
                <InvoiceContent
                  title="Collected"
                  amount={invoice?.payments
                    ?.filter(
                      (amt) =>
                        amt?.modeDetails?.upiTrxStatus === 'success' ||
                        amt.modeOfPayment === 'CASH' ||
                        amt.modeOfPayment === 'CHEQUE'
                    )
                    .reduce((acc, pmt) => acc + (pmt.amountCollected || 0), 0)}
                />
                <div class="flex-2">
                  <button
                    type="button"
                    class="text-white bg-dark_blue px-5 py-2 rounded-lg"
                    onClick={() => gotToInvoice(invoice.id)}
                  >
                    Go to Invoice
                  </button>
                </div>
              </div>
              <FailedMessage isFailed={invoice.state == 'FAILED'} message={invoice.stateMessage} />
            </PaymentModeContent>
          </PaymentModeContainer>
        </PaymentModeContent>
      ))}
    </div>
  );
}

function PickupCard(props) {
  const { data = [], gotToInvoice, selectedInvoices, setSelectedInvoices } = props;
  if (data.length == 0) return <></>;

  return (
    <div>
      {data.map((invoice) => (
        <PaymentModeContent key={invoice.id}>
          <PaymentModeContainer>
            <div class="flex flex-row items-center justify-between bg-background px-6 py-4 rounded-lg">
              <InvoiceHeader
                selectedInvoices={selectedInvoices}
                setSelectedInvoices={setSelectedInvoices}
                invoice={invoice}
                checkbox={invoice.multiSelectable}
              />
              <FailedSRN invoice={invoice} {...props} />
            </div>
            <PaymentModeContent>
              <div class="flex justify-end">
                <button
                  type="button"
                  class="text-white bg-teal px-5 py-2 rounded-lg"
                  onClick={() => gotToInvoice(invoice.id)}
                >
                  Go to Pickup
                </button>
              </div>
              <FailedMessage isFailed={invoice.state == 'FAILED'} message={invoice.stateMessage} />
            </PaymentModeContent>
          </PaymentModeContainer>
        </PaymentModeContent>
      ))}
    </div>
  );
}
function FailureSRNAndRetry(props) {
  const {
    clientPreference: { enableRetrys },
  } = useGState((s) => ({ clientPreference: s.clientPreference }));
  const permissions = useGState((s) => s.permission);
  const userHasEditPermission = permissions.some((p) => p && p.code === 'tms.execution.edit');

  const { data, isPickup, handleReturn } = props;

  return (
    <div class="flex flex-col items-end xl:flex-row">
      {data.displayState ? (
        <CustomizedMenus
          value={data.displayState}
          disabled={true}
          buttonCls="py-2 px-0 rounded-lg"
          data={deliveryStateOptions(data.supplier, isPickup, data.displayState)}
        />
      ) : null}
      {data.isFailed && (
        <CustomizedMenus
          value={data.returnState}
          disabled={!data.editable || !userHasEditPermission}
          buttonCls="py-2 px-0 mt-4 md:mt-0 md:ml-3 rounded-lg"
          data={getFailureOptions(enableRetrys)}
          onValueChange={(item) => handleReturn(data.invoiceId, item.text)}
        />
      )}
    </div>
  );
}
function MapCard() {
  const branch = useGState((s) => s.branch);
  const mapRef = useRef();
  const shiftPressed = useKeyPress('Shift');
  const google = useMemo(() => window.google, []);
  const bounds = useMemo(() => {
    let bounds = new google.maps.LatLngBounds();
    if (branch)
      bounds.extend({
        lat: branch?.locations?.[0].latitude,
        lng: branch?.locations?.[0].longitude,
      });
    return bounds;
  }, [branch, google.maps.LatLngBounds]);

  return (
    <GoogleMap
      id="retailer_page"
      mapContainerStyle={{
        height: '100%',
        width: '100%',
        borderRadius: 15,
      }}
      onLoad={(map) => {
        mapRef.current = map;
        map.fitBounds(bounds);
        // map.mapTypes.set(
        //   'OSM',
        //   new google.maps.ImageMapType({
        //     getTileUrl: function (coord, zoom) {
        //       return (
        //         'https://tile.openstreetmap.org/' + zoom + '/' + coord.x + '/' + coord.y + '.png'
        //       );
        //     },
        //     tileSize: new google.maps.Size(256, 256),
        //     name: 'OSM',
        //     maxZoom: 18,
        //   })
        // );
      }}
      bounds={bounds}
      options={{
        styles: mapStyles,
        streetViewControl: false,
        draggable: shiftPressed ? false : true,
        scaleControl: false,
        mapTypeControl: false,
        panControl: false,
        zoomControl: false,
        rotateControl: false,
        fullscreenControl: false,
      }}
    >
      {branch && (
        <Marker
          position={{ lat: branch?.locations?.[0].latitude, lng: branch?.locations?.[0].longitude }}
        />
      )}
    </GoogleMap>
  );
}
