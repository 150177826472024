import React from 'react';
import { Box } from '@material-ui/core';
import { a } from './invoiceReducer';
import { useGState, ga } from 'state/store';
import Select from 'react-select';
import { byId } from 'utils/utils';
import { useSelectOptions } from 'utils/customHooks';

export default function DeliveryStateSelector({
  state: { displayState, supplier, stateMessage, isFailed, editable },
  dispatch,
}) {
  const failureReasons = useGState((s) => s[ga.FAILURE_REASONS]);
  const failureOptions = useSelectOptions(failureReasons, [failureReasons]);
  const stateOptions = useSelectOptions([supplier ? 'PICKED' : 'DELIVERED', 'FAILED'], [supplier]);

  return (
    <Box width={1} pr={1}>
      <Select
        placeholder="State"
        value={byId(stateOptions, displayState, 'value')}
        options={stateOptions}
        isDisabled={!editable}
        onChange={(val) => {
          dispatch([a.STATE, val.value]);
        }}
      ></Select>
      {isFailed && (
        <Box py={1} width={1}>
          <Select
            placeholder="Failure Reason"
            value={byId(failureOptions, stateMessage, 'value')}
            options={failureOptions}
            isDisabled={!editable}
            onChange={(val) => {
              dispatch([a.STATE_REASON, val.value]);
            }}
          />
        </Box>
      )}
    </Box>
  );
}
