import React from 'react';
// import Layout from 'components/Layout';
import { Router } from '@reach/router';
import RetailerAssignment from 'delivery/execution/RetailerAssignment';
import { ReactQueryConfigProvider } from 'react-query';
import Invoice from 'delivery/execution/Invoice';
import OldInvoice from 'delivery/execution/oldInvoice/Invoice';

export default function retailerAssignment(props) {
  return (
    // <Layout {...props}>
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <div class="bg-background min-h-full pb-10">
        <Router>
          <RetailerAssignment {...props} path="/dl/retailerAssignment" />
          <Invoice {...props} path="/dl/retailerAssignment/invoice" />
          <OldInvoice {...props} path="/dl/retailerAssignment/oldInvoice" />
        </Router>
      </div>
    </ReactQueryConfigProvider>
    // </Layout>
  );
}
