import React, { useReducer, useEffect, useState, useCallback } from 'react';
import qs from 'query-string';
import { useFetch, useAPI, useSnackbar } from 'utils/customHooks';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Button,
  Divider,
  TextField,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import { navigate } from 'components/Link';
import invoiceReducer, { a, getPayload } from './invoiceReducer';
import { format0, format2, isVal } from 'utils/utils';
import RemoveIcon from '@material-ui/icons/RemoveRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import POD from './POD';
import Payments from './Payments';
import CButton from 'components/CButton';
import DeliveryStateSelector from './DeliveryStateSelector';
import fetchPromise from 'utils/fetch';

export default function OldInvoice({ location }) {
  const { assignmentId, retailerId, invoiceId } = qs.parse(location.search);
  const [data] = useFetch(
    `/v2/delivery/dlo/invoice?assignmentId=${assignmentId}&retailerId=${retailerId}&invoiceId=${invoiceId}`
  );
  const [fetchApi, , loading] = useAPI();
  const [uploading, setUploading] = useState(false);
  const [state, dispatch] = useReducer(invoiceReducer, { isDirty: false });
  const [podDialogOpen, setPodDialogOpen] = useState(false);
  const [paymentsDialogOpen, setPaymentsDialogOpen] = useState(false);
  const [notif] = useSnackbar();

  const podDialogClose = useCallback(() => {
    setPodDialogOpen(false);
  }, [setPodDialogOpen]);

  const paymentsDialogClose = useCallback(() => {
    setPaymentsDialogOpen(false);
  }, [setPaymentsDialogOpen]);

  useEffect(() => {
    if (!data) return;
    dispatch([a.INIT, data]);
  }, [data]);

  const isInCompleteCheque = () => {
    return state?.payments
      .filter((pmt) => pmt.modeOfPayment === 'CHEQUE')
      .map((i) =>
        (({ amountCollected, modeDetails: { bankId, chequeNo, chequeDate } }) => ({
          amountCollected,
          bankId,
          chequeNo,
          chequeDate,
        }))(i)
      )
      .some(
        (pmt) => !(Number(pmt.amountCollected) > 0 && pmt.bankId && pmt.chequeNo && pmt.chequeDate)
      );
  };

  const [triggerSave, setTriggerSave] = useState(false);

  const showIncompleteChequeError = () => {
    const error = 'Cheque details are not completed!';
    notif(error, { variant: 'error' });
  };

  const getUploadApiDetails = async (image) => {
    const blob = await (await fetch(image)).blob();
    var formData = new FormData();
    formData.append('file', blob);
    return {
      method: 'POST',
      url: `/v2/client/image`,
      data: formData,
    };
  };

  const getLoadingStatus = () => loading || uploading;
  async function uploadAllImages(images) {
    setUploading(true);
    Promise.all(
      images.map(async (img) =>
        fetchPromise(await getUploadApiDetails(img)).then((res) => res.imageUrl)
      )
    ).then((urls) => {
      dispatch([a.POD_IMG, urls]);
      setUploading(false);
      setTriggerSave(true);
    });
  }

  //use-case: saving invoice after state.pod.images have been updated
  useEffect(() => {
    const saveUpdateInvoice = () => {
      const payload = getPayload(state);
      fetchApi(
        {
          method: 'PUT',
          url: `/v2/delivery/dlo/invoice/state?assignmentId=${assignmentId}`,
          data: payload,
        },
        () => {
          dispatch([a.SAVED]);
          setTriggerSave(false);
        }
        // (error) => console.log(error)
      );
    };
    triggerSave && saveUpdateInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSave]);

  const saveInvoice = async () => {
    if (isInCompleteCheque()) {
      showIncompleteChequeError();
      return;
    }

    state.image_upload ? uploadAllImages(state.pod_images) : setTriggerSave(true);
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            onClick={() =>
              navigate(
                `/dl/retailerAssignment?assignmentId=${assignmentId}&retailerId=${retailerId}`
              )
            }
          >
            <ArrowBackIcon />
          </IconButton>
          <Box display="flex" justifyContent="space-between" width={1}>
            <Box p={1}>
              <Typography variant="h6">{data?.code}</Typography>
            </Box>
            <CButton
              disabled={!state.isDirty}
              loading={getLoadingStatus()}
              onClick={saveInvoice}
              color="secondary"
              outlined={true}
            >
              <Typography>SAVE</Typography>
            </CButton>
          </Box>
        </Toolbar>
      </AppBar>
      {state.DATA_LOADED && (
        <>
          <Box display="flex" alignItems="center" justifyContent="space-around" mt={2} mx={2}>
            <Box mr={1}>
              <TextField
                size="small"
                label="Sales Return"
                value={format0(state?.srnValue || 0)}
                onChange={(e) => {
                  dispatch([a.SRN_VALUE, e.target.value]);
                }}
                disabled={!state.editable}
                variant="outlined"
              />
            </Box>
            <Box ml={1}>
              <TextField
                size="small"
                label="Damage"
                value={state?.damageReturn}
                onChange={(e) => {
                  dispatch([a.DAMAGE_RETURN, e.target.value]);
                }}
                disabled={!state.editable}
                variant="outlined"
              />
            </Box>
          </Box>

          <Box m={2} display="flex" alignItems="center" justifyContent="space-between">
            <Box minWidth={1 / 2}>
              <DeliveryStateSelector {...{ state, dispatch }} />
            </Box>
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setPaymentsDialogOpen(true)}
                disabled={state.isFailed}
              >
                Payments
              </Button>
            </Box>

            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setPodDialogOpen(true)}
                disabled={state.isFailed}
              >
                POD
              </Button>
            </Box>
          </Box>
          <Box></Box>
          <POD
            {...{
              podDialogOpen,
              podDialogClose,
              saveInvoice,
              loading: getLoadingStatus(),
              state,
              dispatch,
            }}
          ></POD>
          <Payments
            {...{
              paymentsDialogOpen,
              paymentsDialogClose,
              saveInvoice,
              loading: getLoadingStatus(),
              state,
              dispatch,
            }}
          ></Payments>
        </>
      )}
      <Divider />
      {state?.lineItems?.map((lineItem) => (
        <LineItem
          key={lineItem.id}
          srnValue={state?.srnValue || 0}
          lineItem={lineItem}
          dispatch={dispatch}
        />
      ))}
    </>
  );
}

function LineItem({
  srnValue,
  lineItem: { id, skuName, price, deliveredQuantity, quantity },
  dispatch,
}) {
  const qty = isVal(deliveredQuantity) ? deliveredQuantity : quantity;
  const onQtyChange = (qty, qtyToAddOrRemove) => {
    dispatch([a.SKU_QTY, { id, qty: qty }]);
    dispatch([a.SRN_VALUE, srnValue + qtyToAddOrRemove * price]);
  };

  return (
    <Box m={2} display="flex" alignItems="center" justifyContent="space-between">
      <Box flexGrow={1}>
        <Typography>{skuName}</Typography>
        <Typography color="textSecondary" variant="caption">{`₹${format2(
          price
        )} * ${qty} = ₹${format0(price * qty)}`}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <IconButton disabled={qty === 0} onClick={() => onQtyChange(Math.max(qty - 1, 0), 1)}>
          <RemoveIcon />
        </IconButton>
        <Typography>{`${qty}/${quantity}`}</Typography>
        <IconButton
          disabled={qty === quantity}
          onClick={() => onQtyChange(Math.min(qty + 1, quantity), -1)}
        >
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
