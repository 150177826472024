import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/CloseRounded';
import {
  Slide,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Dialog,
  Box,
  TextField,
  Button,
} from '@material-ui/core';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { a } from './invoiceReducer';
import CButton from 'components/CButton';
import { MobileDatePicker } from '@material-ui/pickers';
import { formatDate } from 'utils/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {
    margin: theme.spacing(1),
  },
}));

export default function POD({
  podDialogClose,
  podDialogOpen,
  saveInvoice,
  loading,
  state,
  dispatch,
}) {
  const classes = useStyles();
  const readOnly = !state.editable;
  const handleImageUpload = (event) => {
    var files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fReader = new FileReader();
      fReader.readAsDataURL(file);
      fReader.onloadend = () => {
        dispatch([a.TEMP_POD_IMG, fReader.result]);
      };
    }
  };
  return (
    <Dialog
      fullScreen
      open={podDialogOpen}
      onClose={podDialogClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton edge="start" color="inherit" onClick={podDialogClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            POD
          </Typography>

          <CButton
            disabled={!state.isDirty}
            loading={loading}
            onClick={() => {
              saveInvoice();
              // podDialogClose();
            }}
            color="secondary"
            outlined={true}
          >
            <Typography>SAVE</Typography>
          </CButton>
        </Toolbar>
      </AppBar>
      <Box display="flex" alignItems="center" justifyContent="space-between" m={1} pt={1}>
        <TextField
          className={classes.textField}
          size="small"
          label="GRN No."
          value={state?.pod?.extraFields?.grnNo}
          onChange={(e) => {
            dispatch([a.POD_EXTRA, { key: 'grnNo', value: e.target.value }]);
          }}
          disabled={readOnly}
          variant="outlined"
        />
        <MobileDatePicker
          className={classes.textField}
          autoOk
          variant="outlined"
          label="GRN Date"
          size="small"
          placeholder="GRN Date"
          format="dd-MM-yyyy"
          mask="__-__-____"
          clearable
          value={state?.pod?.extraFields?.grnDate || null}
          onChange={(date) =>
            dispatch([
              a.POD_EXTRA,
              { key: 'grnDate', value: date && !isNaN(date) ? formatDate(date) : undefined },
            ])
          }
          disabled={readOnly}
        />
        <TextField
          className={classes.textField}
          size="small"
          label="GRN Amount"
          value={state?.pod?.extraFields?.grnAmount}
          onChange={(e) => {
            dispatch([a.POD_EXTRA, { key: 'grnAmount', value: e.target.value }]);
          }}
          disabled={readOnly}
          variant="outlined"
        />
        <label htmlFor="pod_image">
          <input
            style={{ display: 'none' }}
            id="pod_image"
            name="pod_image"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            disabled={readOnly}
            multiple={true}
          />
          <Button
            color="secondary"
            variant="contained"
            component="span"
            startIcon={<AddPhotoAlternateIcon />}
            disabled={readOnly}
          >
            Add Image
          </Button>
        </label>
      </Box>
      <Box m={2} mt={0}>
        <Box pb={1}>
          <Typography variant="h6" color="textSecondary">
            Images
          </Typography>
        </Box>
        {(state?.pod?.images || []).concat(state.pod_images || [])?.map((img, index) => (
          <Box maxWidth={1} key={index}>
            <img
              // key={index}
              src={img}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Box>
        ))}
      </Box>
    </Dialog>
  );
}
